import clsx from 'clsx'
import PropTypes from 'prop-types'

import { CompaniesOptionsFull, StateOptions, TApi } from '../../../../shared/const'

import styles from './style.module.scss'
import { useTranslation } from 'react-i18next'
import Avatar from 'react-avatar'
import Logo from '../../../../assets/icons/logo.svg'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const PrevArrow = ({ className, style, onClick }) => (
	<button
		style={{ ...style, left: 15, backgroundColor: '#E5EAF5' }}
		onClick={onClick}
		className={clsx(className, styles.sliderBtn)}>
		<div>back</div>
	</button>
)
const NextArrow = ({ className, style, onClick }) => (
	<button
		style={{ ...style, right: 15, backgroundColor: '#E5EAF5' }}
		onClick={onClick}
		className={clsx(className, styles.sliderBtn)}>
		<div>next</div>
	</button>
)
export default function CardCompany({ company, className }) {
	const { t } = useTranslation()

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />
	}

	const showSlider = (company.socialNet && company.socialNet.length) || company.email

	return (
		<div className={clsx(styles.root, className)}>
			<Slider {...settings} className={styles.slider}>
				<Avatar
					name={
						company.logoHashName ? company.name : t('You can change the logo on the "Company Profile" page')
					}
					initials="Company logo"
					className={styles.companyLogo}
					color="#e5eaf5"
					size={200}
					src={company.logoHashName ? TApi.PUBLIC_PROFILE_AVATAR(company.logoHashName) : Logo}
				/>
				<div className={styles.descContainer}>{company.description}</div>
			</Slider>
			<div className={styles.name}>{company.name}</div>
			{company.websiteURL && (
				<div className={styles.website}>
					<a className={styles.websiteURL} href={company.websiteURL}>
						{company.websiteURL.replace(/^https:\/\//, '')}
					</a>
				</div>
			)}
			<div className={styles.companyType}>
				{CompaniesOptionsFull.find(({ value }) => value === company.companyType).label}
			</div>

			<div className={styles.school}>{company.TeachersSupervisions?.[0]?.Teacher?.School?.name || '-'}</div>
			<div className={styles.state}>{t(StateOptions.find(({ value }) => value === company.state).label)}</div>
			{showSlider && (
				<Slider {...settings} className={styles.slider}>
					{company.email && (
						<div className={styles.linkContainer}>
							<a
								className={styles.link}
								href={`mailto:${company.email}`}
								target="_blank"
								rel="noreferrer">
								E-Mail
							</a>
						</div>
					)}
					{company.socialNet
						?.filter(s => s.public)
						.map(social => (
							<div className={styles.linkContainer}>
								<a className={styles.link} href={social.link} target="_blank" rel="noreferrer">
									{social.name}
								</a>
							</div>
						))}
				</Slider>
			)}
		</div>
	)
}

CardCompany.propTypes = {
	className: PropTypes.string,
	company: PropTypes.array.isRequired
}
