import React from 'react'
import styles from '../../style.module.scss'
import Joi from 'joi'
import { useFieldArray, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { joiResolver } from '@hookform/resolvers/joi'
import SelectField from '../../../../../../../../UI/SelectField'
import TextAreaField from '../../../../../../../../UI/TextAreaField'
import Btn from '../../../../../../../../UI/Btn'
import clsx from 'clsx'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { schemaOptions } from '../../../../../../../../shared/i18n'
import { useTranslation } from 'react-i18next'
import { optionsDateWhioutTime } from '../../../../../../../../shared/const'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import TextFieldNumber from '../../../../../../../../UI/TextFieldNumber'
import Decimal from 'decimal.js'
function CompanyRevenues(props) {
	const { t } = useTranslation()
	const schema = Joi.object({
		revenues: Joi.array().items(
			Joi.object().keys({
				vat: Joi.number().required(),
				turnoverTotal: Joi.number().required().messages({
					'number.base': `must be a number`,
					'any.required': `is a required field`
				}),
				vatOfPercent: Joi.number()
					.custom((value, helper) => {
						if (
							new Decimal(helper.state.ancestors[0].turnoverTotal)
								.div(new Decimal(100).plus(helper.state.ancestors[0].vat))
								.mul(helper.state.ancestors[0].vat)
								.toDecimalPlaces(2)
								.toNumber() === value
						)
							return value
						return helper.message(t('Wrong result'))
					})
					.required()
					.messages({
						'number.base': `must be a number`,
						'any.required': `is a required field`
					})
			})
		),
		revenuesTaxFreeTotal: Joi.optional(),
		revenuesTaxFreeDeclarations: Joi.any().custom((value, helper) => {
			if (value?.length > 10000) return helper.message(t('is must be less than 10000 characters'))
			return value
		})
	})

	const [validate, setValidate] = useState(false)
	const {
		register,
		reset,
		handleSubmit,
		trigger,
		watch,
		control,
		formState: { errors }
	} = useForm({
		resolver: validate
			? null
			: joiResolver(schema, {
					errors: {
						labels: false,
						language: localStorage.getItem('i18nextLng')
					},
					...schemaOptions
			  })
	})
	const watchAll = watch()
	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	useEffect(() => {
		const { error, value } = schema.validate(props.form)
		console.log(value)
		console.log(error)
		console.log(watchAll) // Log the entire form state
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchAll])

	const { fields, append } = useFieldArray({
		control,
		name: 'revenues' // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	})

	const location = useLocation()
	const { type } = queryString.parse(location.search)

	const watchRevenues = watch('revenues')
	const watchRevenuesTaxFreeTotal = watch('revenuesTaxFreeTotal')

	const addForm = () => {
		if (fields.length < 3) append({})
	}

	const deleteForm = id => {
		const form = fields.map(({ id, ...fields }) => {
			return fields
		})

		form.splice(id, 1)

		props
			.updateData({
				revenues: form
			})
			.then(() => {
				toast.success(t('Revenues was deleted'))
			})
	}

	const onSubmit = form => {
		let data = form
		for (const key in data) {
			if (data[key] === '') {
				data[key] = null
			}
		}

		props
			.updateData(data)
			.then(() => {
				toast.success(t('Revenues and sales was updated'))
				if (!validate) props.handlerNextStep()
			})
			.finally(() => setValidate(false))
	}
	useEffect(() => {
		reset({
			revenues: Array.isArray(props.form?.revenues) ? props.form?.revenues : [],
			revenuesTaxFreeTotal: props.form?.revenuesTaxFreeTotal || new Decimal(0).toNumber(),
			revenuesTaxFreeDeclarations: props.form?.revenuesTaxFreeDeclarations || ''
		})
		if (!Array.isArray(props.form?.revenues) || props.form?.revenues?.length === 0) {
			append({
				vat:
					props.vatRateRevenues?.values?.[props.vatRateRevenues?.default]?.value || new Decimal(0).toNumber(),
				turnoverTotal: props.form?.revenuesTaxFreeTotal || new Decimal(0).toNumber(),
				vatOfPercent: props.form?.revenuesTaxFreeDeclarations || new Decimal(0).toNumber()
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	useEffect(() => {
		trigger()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchRevenuesTaxFreeTotal])

	if (props.currentStep !== props.needStep) return null
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<div className={styles.sectionTitle} />
				<div className={styles.sectionContent}>
					<p className={styles.deadline}>{`${t('Deadline by')} ${new Date(
						type === 'annual' ? props.reportsDeadline?.JC_annual : props.reportsDeadline?.JC_semiannual
					).toLocaleDateString('de-DE', optionsDateWhioutTime)}`}</p>
					<p className={styles.title}>
						{t('Income from the sale of company shares does not count towards your sales!')}
					</p>
				</div>
			</section>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Turnovers in € incl. VAT')}</h3>
				<div className={styles.sectionContent}>
					{fields.map((item, index) => (
						<div key={item.id}>
							<p className={styles.description}>
								{t('Here you can create new groups of turnovers, with different VAT rates.')}
							</p>
							<div className={styles.flexContainer}>
								<SelectField
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.threeInline}
									mb={20}
									label={t('VAT rate')}
									control={control}
									name={`revenues.${index}.vat`}
									placeholder={`<${t('VAT')}>%`}
									options={props.vatRateRevenues?.values
										?.map(v => ({
											value: v.value,
											label: v.value + '%'
										}))
										.filter(option => {
											if (option.value === watchRevenues[index]?.vat) return true
											return !watchRevenues?.find(item => item.vat === option.value)
										})}
								/>

								<TextFieldNumber
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.threeInline}
									mb={20}
									label={t('Turnover total')}
									name={`revenues.${index}.turnoverTotal`}
									control={control}
									placeholder="00.000,00"
									error={errors.revenues?.[index]?.turnoverTotal}
								/>

								<TextFieldNumber
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.threeInline}
									mb={20}
									label={`${t('of which')} ${watchRevenues?.[index]?.vat || ''}${t('% VAT in €')}`}
									name={`revenues.[${index}].vatOfPercent`}
									control={control}
									placeholder="00.000,00"
									error={errors.revenues?.[index]?.vatOfPercent}
								/>
								<Btn
									disabled={
										props.form?.isLoading || props.form?.isSubmitted || props.isTeacherStudentView
									}
									className={styles.deleteBtn}
									theme="white"
									icon="delete"
									iconColor="#000"
									onClick={() => deleteForm(index)}
								/>
							</div>
						</div>
					))}

					<div className={clsx(styles.flexContainer, styles.addBtnContainer)}>
						<Btn
							disabled={props.form?.isLoading || props.form?.isSubmitted || props.isTeacherStudentView}
							theme="white"
							icon="plus"
							iconColor="#22404D"
							iconSize={{ w: 9, h: 9 }}
							type="button"
							onClick={addForm}
						/>
						<p className={styles.addBtnText}>{t('Create new entry')}</p>
					</div>
				</div>
			</section>

			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Junior tax-exempt revenues')}</h3>
				<div className={styles.sectionContent}>
					<p className={styles.description}>
						{t(
							'These revenues do not count towards the calculation of junior corporate tax and do not include VAT - e.g. sponsorship.'
						)}
					</p>

					<TextFieldNumber
						disabled={props.form?.isLoading || props.form?.isSubmitted}
						mb={20}
						label={t('Total in €')}
						name={'revenuesTaxFreeTotal'}
						control={control}
						placeholder="00.000,00"
						error={errors.revenuesTaxFreeTotal}
					/>

					<p className={styles.description}>
						{t(
							'For VAT-exempt revenue from sponsorship, the company must provide a consideration in the form of advertising or public relations. Describe here what consideration you will provide for each sponsorship received.'
						)}
					</p>

					<TextAreaField
						disabled={props.form?.isLoading || props.form?.isSubmitted}
						classNameLabel={styles.textArea}
						height={100}
						mb={20}
						maxLength={2000}
						showLettersLeft
						label=""
						register={register('revenuesTaxFreeDeclarations')}
						error={errors.revenuesTaxFreeDeclarations}
					/>

					<div className={clsx(styles.flexContainer, styles.btnContainer)}>
						{props.form?.isSubmitted || props.isTeacherStudentView ? (
							<Btn className={styles.btn} type="button" onClick={() => props.handlerNextStep()}>
								{t('Next')}
							</Btn>
						) : (
							<>
								<Btn
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.btn}
									type="submit"
									theme="whiteBackground">
									{t('Validate')}
								</Btn>
								<Btn
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.btn}
									type="submit"
									onClick={() => setValidate(true)}>
									{t('save')}
								</Btn>
							</>
						)}
					</div>
				</div>
			</section>
		</form>
	)
}
CompanyRevenues.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,
	vatRateRevenues: PropTypes.array.isRequired,
	reportsDeadline: PropTypes.object.isRequired,

	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	vatRateRevenues: state.options.vatRateRevenues,
	reportsDeadline: state.options.reportsDeadline,
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps, null)(CompanyRevenues)
