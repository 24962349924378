import React, { useEffect, useState } from 'react'
import styles from '../../style.module.scss'
import Joi from 'joi'
import { useFieldArray, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import PropTypes from 'prop-types'
import SelectField from '../../../../../../../../UI/SelectField'
import TextAreaField from '../../../../../../../../UI/TextAreaField'
import Btn from '../../../../../../../../UI/Btn'
import clsx from 'clsx'
import { schemaOptions } from '../../../../../../../../shared/i18n'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TextFieldNumber from '../../../../../../../../UI/TextFieldNumber'
import Decimal from 'decimal.js'

function CompanyExpenditure(props) {
	const { t } = useTranslation()

	const schema = Joi.object({
		materialExpensesItems: Joi.array().items(
			Joi.object().keys({
				vat: Joi.number().required(),
				materialExpensesTotal: Joi.number().required().messages({
					'number.base': `must be a number`,
					'any.required': `is a required field`
				}),
				vatOfPercent: Joi.number()
					.custom((value, helper) => {
						const { materialExpensesTotal, vat } = helper.state.ancestors[0]
						const calculatedValue = new Decimal(materialExpensesTotal)
							.div(new Decimal(100).plus(vat))
							.mul(vat)
							.mul(100)
							.toDecimalPlaces(2)
							.toNumber()
						if (Math.round(calculatedValue) / 100 === value) return value
						return helper.message(t('Wrong result'))
					})
					.required()
					.messages({
						'number.base': `must be a number`,
						'any.required': `is a required field`
					})
			})
		),
		otherExpensesItems: Joi.array().items(
			Joi.object().keys({
				vat: Joi.number().required(),
				otherExpensesTotal: Joi.number().required().messages({
					'number.base': `must be a number`,
					'any.required': `is a required field`
				}),
				vatOfPercent: Joi.number()
					.custom((value, helper) => {
						const { otherExpensesTotal, vat } = helper.state.ancestors[0]
						const calculatedValue = new Decimal(otherExpensesTotal)
							.div(new Decimal(100).plus(vat))
							.mul(vat)
							.mul(100)
							.toDecimalPlaces(2)
							.toNumber()
						if (Math.round(calculatedValue) / 100 === value) return value
						return helper.message(t('Wrong result'))
					})
					.required()
					.messages({
						'number.base': `must be a number`,
						'any.required': `is a required field`
					})
			})
		),
		expensesTaxFreeTotal: Joi.optional(),
		expensesTaxFreeDeclarations: Joi.any().custom((value, helper) => {
			if (value?.length > 10000) return helper.message(t('is must be less than 10000 characters'))
			return value
		})
	})

	const [validate, setValidate] = useState(false)
	const {
		register,
		reset,
		watch,
		handleSubmit,
		control,
		trigger,
		formState: { errors }
	} = useForm({
		resolver: validate
			? null
			: joiResolver(schema, {
					errors: {
						labels: false,
						language: localStorage.getItem('i18nextLng')
					},
					...schemaOptions
			  })
	})
	const { fields: filedsMaterial, append: appendMaterial } = useFieldArray({
		control,
		name: 'materialExpensesItems' // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	})
	const { fields: filedsOthers, append: appendOthers } = useFieldArray({
		control,
		name: 'otherExpensesItems' // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	})

	const watchVatMaterial = watch('materialExpensesItems')
	const watchVatOther = watch('otherExpensesItems')

	const addFormFirst = () => {
		if (filedsMaterial.length < 3) return appendMaterial({})
	}
	const deleteFormFirst = id => {
		const form = filedsMaterial.map(({ id, ...fields }) => {
			return fields
		})
		form.splice(id, 1)

		props
			.updateData({
				materialExpensesItems: form
			})
			.then(() => {
				toast.success(t('Material expenses were deleted'))
			})
	}

	const addFormSecond = () => {
		if (filedsOthers.length < 3) return appendOthers({})
	}
	const deleteFormSecond = id => {
		const form = filedsOthers.map(({ id, ...fields }) => {
			return fields
		})
		form.splice(id, 1)

		props.updateData({ otherExpensesItems: form }).then(() => {
			toast.success(t('Other expenses were deleted'))
		})
	}
	const onSubmit = form => {
		let data = form
		for (const key in data) {
			if (data[key] === '') {
				data[key] = null
			}
		}

		props
			.updateData(data)
			.then(() => {
				toast.success(t('Expenses and input tax deduction was updated'))
				if (!validate) props.handlerNextStep()
			})
			.finally(() => setValidate(false))
	}

	useEffect(() => {
		reset({
			otherExpensesItems: props.form?.otherExpensesItems,
			materialExpensesItems: props.form?.materialExpensesItems,
			expensesTaxFreeTotal: props.form?.expensesTaxFreeTotal,
			expensesTaxFreeDeclarations: props.form?.expensesTaxFreeDeclarations
		})
		if (!props.form?.otherExpensesItems?.length) {
			appendOthers({
				vat: props.vatRateExpenditure?.values?.[props.vatRateExpenditure?.default]?.value,
				otherExpensesTotal: null,
				vatOfPercent: null
			})
		}
		if (!props.form?.materialExpensesItems?.length) {
			appendMaterial({
				vat: props.vatRateExpenditure?.values?.[props.vatRateExpenditure?.default]?.value,
				materialExpensesTotal: null,
				vatOfPercent: null
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.form])

	useEffect(() => {
		if (Object.keys(errors).length) trigger()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('i18nextLng')])

	if (props.currentStep !== props.needStep) return null
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Expenses and input tax deduction')}</h3>
				<div className={styles.sectionContent}>
					<p className={styles.description}>
						{t(
							'Enter the total of your material and other expenses and the input tax (sales tax) included in them. The input tax is offset against the sales tax collected on your sales and reduces the tax payment.'
						)}
					</p>
				</div>
			</section>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Material expenses')}</h3>
				<div className={styles.sectionContent}>
					{filedsMaterial.map((item, index) => (
						<div key={item.id} className={styles.flexContainer}>
							<SelectField
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.threeInline}
								mb={20}
								label={t('Sales tax rate')}
								control={control}
								name={`materialExpensesItems.${index}.vat`}
								placeholder={`<${t('VAT')}>%`}
								options={props.vatRateExpenditure?.values
									?.map(v => ({
										value: v.value,
										label: v.value + '%'
									}))
									.filter(option => {
										if (option.value === watchVatMaterial[index]?.vat) return true
										return !watchVatMaterial?.find(item => item.vat === option.value)
									})}
							/>

							<TextFieldNumber
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.threeInline}
								mb={20}
								label={t('Material expenses in € incl. <%> VAT.')}
								name={`materialExpensesItems.${index}.materialExpensesTotal`}
								control={control}
								placeholder="00.000,00"
								error={errors.materialExpensesItems?.[index]?.materialExpensesTotal}
							/>

							<TextFieldNumber
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.threeInline}
								mb={20}
								label={`${t('of which')} ${watchVatMaterial[index]?.vat}${t('% sales tax in €')}`}
								name={`materialExpensesItems.${index}.vatOfPercent`}
								control={control}
								placeholder="00.000,00"
								error={errors.materialExpensesItems?.[index]?.vatOfPercent}
							/>
							<Btn
								disabled={
									props.form?.isLoading || props.form?.isSubmitted || props.isTeacherStudentView
								}
								className={styles.deleteBtn}
								theme="white"
								icon="delete"
								iconColor="#000"
								onClick={() => deleteFormFirst(index)}
							/>
						</div>
					)) || <></>}
					<div className={clsx(styles.flexContainer, styles.addBtnContainer)}>
						<Btn
							disabled={props.form?.isLoading || props.form?.isSubmitted || props.isTeacherStudentView}
							theme="white"
							icon="plus"
							iconColor="#22404D"
							iconSize={{ w: 9, h: 9 }}
							type="button"
							onClick={addFormFirst}
						/>
						<p className={styles.addBtnText}>{t('Create new entry')}</p>
					</div>
				</div>
			</section>
			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Other expenses')}</h3>
				<div className={styles.sectionContent}>
					{filedsOthers.map((item, index) => (
						<div key={item.id} className={styles.flexContainer}>
							<SelectField
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.threeInline}
								mb={20}
								label={t('Sales tax rate')}
								control={control}
								name={`otherExpensesItems.${index}.vat`}
								placeholder={`<${t('VAT')}>%`}
								options={props.vatRateExpenditure?.values
									?.map(v => ({
										value: v.value,
										label: v.value + '%'
									}))
									.filter(option => {
										if (option.value === watchVatOther[index]?.vat) return true
										return !watchVatOther?.find(item => item.vat === option.value)
									})}
							/>

							<TextFieldNumber
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.threeInline}
								mb={20}
								label={t('Other expenses in € incl. <%> VAT.')}
								name={`otherExpensesItems.${index}.otherExpensesTotal`}
								control={control}
								placeholder="00.000,00"
								error={errors.otherExpensesItems?.[index]?.otherExpensesTotal}
							/>

							<TextFieldNumber
								disabled={props.form?.isLoading || props.form?.isSubmitted}
								className={styles.threeInline}
								mb={20}
								label={`${t('of which')} ${watchVatOther[index]?.vat}${t('% sales tax in €')}`}
								name={`otherExpensesItems.${index}.vatOfPercent`}
								control={control}
								placeholder="00.000,00"
								error={errors.otherExpensesItems?.[index]?.vatOfPercent}
							/>
							<Btn
								disabled={
									props.form?.isLoading || props.form?.isSubmitted || props.isTeacherStudentView
								}
								className={styles.deleteBtn}
								theme="white"
								icon="delete"
								iconColor="#000"
								onClick={() => deleteFormSecond(index)}
							/>
						</div>
					))}
					<div className={clsx(styles.flexContainer, styles.addBtnContainer)}>
						<Btn
							disabled={props.form?.isLoading || props.form?.isSubmitted || props.isTeacherStudentView}
							theme="white"
							icon="plus"
							iconColor="#22404D"
							iconSize={{ w: 9, h: 9 }}
							type="button"
							onClick={addFormSecond}
						/>
						<p className={styles.addBtnText}>{t('Create new entry')}</p>
					</div>
				</div>
			</section>

			<section className={styles.section}>
				<h3 className={styles.sectionTitle}>{t('Other expenses not subject to sales tax')} </h3>
				<div className={styles.sectionContent}>
					<TextFieldNumber
						disabled={props.form?.isLoading || props.form?.isSubmitted}
						mb={20}
						label={t('Total in €')}
						name={'expensesTaxFreeTotal'}
						control={control}
						placeholder="00.000,00"
						error={errors.expensesTaxFreeTotal}
					/>

					<TextAreaField
						disabled={props.form?.isLoading || props.form?.isSubmitted}
						classNameLabel={styles.textArea}
						height={100}
						mb={20}
						maxLength={2000}
						label={t('Please enter an accurate list of VAT-exempt expenses here.')}
						register={register('expensesTaxFreeDeclarations')}
						error={errors.expensesTaxFreeDeclarations}
					/>

					<div className={clsx(styles.flexContainer, styles.btnContainer)}>
						{props.form?.isSubmitted || props.isTeacherStudentView ? (
							<>
								<Btn className={styles.btn} type="button" onClick={() => props.handlerPrevStep()}>
									{t('Previous')}
								</Btn>
								<Btn className={styles.btn} type="button" onClick={() => props.handlerNextStep()}>
									{t('Next')}
								</Btn>
							</>
						) : (
							<>
								<Btn
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.btn}
									type="submit"
									theme="whiteBackground">
									{t('Validate')}
								</Btn>
								<Btn
									disabled={props.form?.isLoading || props.form?.isSubmitted}
									className={styles.btn}
									type="submit"
									onClick={() => setValidate(true)}>
									{t('save')}
								</Btn>
							</>
						)}
					</div>
				</div>
			</section>
		</form>
	)
}

CompanyExpenditure.propTypes = {
	form: PropTypes.object,
	updateData: PropTypes.func,

	currentStep: PropTypes.number,
	needStep: PropTypes.number,
	handlerNextStep: PropTypes.func,
	handlerPrevStep: PropTypes.func,
	isTeacherStudentView: PropTypes.bool.isRequired
}
const mapStateToProps = state => ({
	vatRateExpenditure: state.options.vatRateExpenditure,
	isTeacherStudentView: state.user.isTeacherStudentView
})
export default connect(mapStateToProps, null)(CompanyExpenditure)
